<template>
  <div class="p-grid">
    <div class="p-col-12">
      <div class="card">
        <TreeTable
          :value="productsCategories"
          selectionMode="single"
          v-model:selection="selectedCategory"
          @node-select="onNodeSelect" 
          @node-unselect="onNodeUnselect"
        >
          <!-- <template #header>
            <Button
              label="Сохранить"
              icon="pi pi-save"
              class="p-button-success p-mr-2"
              @click="saveCategory"
            />
          </template>-->

          <Column field="name" header="Название" :expander="true">
            <!-- <template #body="slotProps">
							<span class="p-column-title">Name</span>
							{{slotProps.node.data.name}}
						</template> -->
          </Column>
          <Column field="description" header="Описание"></Column>
          <Column
            headerStyle="width: 16em"
            headerClass="p-text-center"
            bodyClass="p-text-center"
          >
            <template #header>
              <Button type="button" icon="pi pi-cog"></Button>
            </template>
            <template #body="slotProps"> 
              <div v-if="slotProps.node.key == selectedCategory.key"  >                            
              <Button
                type="button"
                icon="pi pi-plus"
                class="p-button-info"
                style="margin-right: 0.5em"
                @click="openNew"
              ></Button>
              <Button
                type="button"
                icon="pi pi-pencil"
                class="p-button-success"
                style="margin-right: 0.5em"
                @click="editCategory(slotProps.node.data)"
              ></Button>
              <Button
                type="button"
                icon="pi pi-trash"
                class="p-button-warning"
                @click="confirmDeleteCategory"
              ></Button>
              </div>
            </template>
          </Column>
        </TreeTable>

        <Dialog
          v-model:visible="categoriesDialog"
          :style="{ width: '450px' }"
          header="Confirm"
          :modal="true"
          class="p-fluid"
        >
          <div class="p-field">
            
            <label for="name">Name*</label>
            <InputText
              id="name"
              v-model.trim="category.name"
              required="true"
              autofocus
              :class="{ 'p-invalid': submitted && !category.name }"
            />
            <small class="p-invalid" v-if="submitted && !category.name"
              >Name is required.</small
            >
          </div>
          <div class="p-field">
            <label for="description">Description*</label>
            <InputText
              id="description"
              v-model.trim="category.description"
              required="true"
              autofocus
              :class="{ 'p-invalid': submitted && !category.description }"
            />
            <small
              class="p-invalid"
              v-if="submitted && !category.description"
              >Description is required.</small
            >
          </div>
          <template #footer>
            <Button
              label="Cancel"
              icon="pi pi-times"
              class="p-button-text"
              @click="hideDialog"
            />
            <Button
              label="Save"
              icon="pi pi-check"
              class="p-button-text"
              :disabled="!category.name"
              @click="saveCategory"
            />
          </template>
        </Dialog>

        <Dialog
          v-model:visible="deleteCategoryDialog"
          :style="{ width: '450px' }"
          header="Confirm"
          :modal="true"
        >
          <div class="confirmation-content">
            <i
              class="pi pi-exclamation-triangle p-mr-3"
              style="font-size: 2rem"
            />
            <span v-if="category"
              >Are you sure you want to delete this category?</span
            >
          </div>
          <template #footer>
            <Button
              label="No"
              icon="pi pi-times"
              class="p-button-text"
              @click="deleteCategoryDialog = false"
            />
            <Button
              label="Yes"
              icon="pi pi-check"
              class="p-button-text"
              @click="deleteCategory"
            />
          </template>
        </Dialog>
      </div>
    </div>
  </div>
</template>

<script>
import CategoriesService from "../service/CategoriesService";
// import firebase from "firebase";
// import 'firebase/storage';

export default {
  data() {
    return {
      productsCategories: null,
      selectedCategory: null,
      deleteCategoryDialog: false,
      category: {},
      data: {},
      categoriesDialog: false,
      submitted: false,
      seenBtn: false,
    };
  },
  categoriesService: null,
  created() {
    this.categoriesService = new CategoriesService();
  },
  mounted() {
    this.categoriesService
      .getCategories()
      .then((data) => {this.productsCategories = data; this.selectedCategory = this.productsCategories[0]});
      
  },
  methods: {
    onNodeSelect(node) {
        //this.$toast.add({severity:'success', summary: 'Node Selected', detail: node.data.name, life: 3000});
        this.selectedCategory = node;
        console.log(node.key);
    },
    onNodeUnselect(node) {
        //this.$toast.add({severity:'success', summary: 'Node Unselected', detail: node.data.name, life: 3000});
        console.log("unselect" + node.data.name);
    },
    showBtn(node) {
      console.log(node)
      if (!node) {
        return true
      } 
      
      return true
    },
    openNew() {
      this.category = {};
      console.log("selected " + this.selectedCategory.key);

      this.submitted = false;
      this.categoriesDialog = true;
    },

    editCategory (cat) {
      console.log(cat)
      this.category = Object.assign({}, cat);
      console.log(this.category)
      this.categoriesDialog = true;
    },

    async saveCategory () {
      console.log(this.category)
      if(this.category.id) {
        this.updateCategory(this.category);
      } else {
        this.addCategory();
      }
    },

    hideDialog() {
      this.categoriesDialog = false;
      this.submitted = false;
    },

    async updateCategory (cat) {
      this.saveChild(this.productsCategories[0], cat);
  
      this.saveCategories();
      this.hideDialog();
    },

    saveChild(node, cat) {
      if(node.key == cat.id){
        node.data = cat;
        return;
      }

      node.children.forEach(element => {
        this.saveChild(element, cat);
      });

    },

    async addCategory() {
      this.submitted = true; 
      console.log(this.category);
      if (!this.category.description) {
        this.category.description = ''
      }
      var id = await this.categoriesService.getCategoryID();
      if (!id) {
        console.log('FATAL ERROR! addCategory() No FireBase id')
        return
      } 
      var cat = {
            "key": id,
            "data":{
                "id": id,  
                "name": this.category.name,
                "products_total":"0",
                "description": this.category.description,
                "type":"Folder"
            },
            "children":[]
        };
        console.log(cat);
      this.addChild(this.productsCategories[0], this.selectedCategory.key, cat);
      this.categoriesDialog = false;
      this.saveCategories();

    },    
    addChild(node, key, cat) {
      if(node.key == key){
        node.children.push(cat);
        return;
      }

      node.children.forEach(element => {
        this.addChild(element, this.selectedCategory.key, cat);
      });

    },

    confirmDeleteCategory(category) {
      this.category = category;
      this.deleteCategoryDialog = true;
    },

    deleteCategory() {
      this.deleteChild(this.productsCategories[0], this.selectedCategory.key);      
      this.deleteCategoryDialog = false;
      this.saveCategories();
    },
    deleteChild(node, key) {
      node.children.forEach(el => {
        if(el.key == key){
          var index = node.children.indexOf(el);
          if (index !== -1) {
            node.children.splice(index, 1);
          }
          return;
        }
        this.deleteChild(el, key)
      });

    },
    async saveCategories() {
       this.submitted = true; 
       await this.categoriesService.setCategories(
          this.productsCategories[0]
        );    
    },
   },
};
</script>

<style scoped>
</style>